import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import { countries, countryType } from '../../assets/countries'
import Card from '@mui/material/Card'
import ListItemButton from '@mui/material/ListItemButton'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import { navigate } from 'gatsby'
import { BanksPaper } from './paperComponents'

const StyledListItem = styled(ListItem)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}))

const SearchTextField = styled(TextField)(({ theme }) => ({
    width: 300,
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
}))

const useStyles = makeStyles({
    bankCard: {
        width: '100%',
        maxHeight: 80,
    },
    countryIcon: {
        fontSize: 48,
    },
})

const listItemImageSx = { width: 100 }

function PickBank() {
    const [searchValue, setSearchValue] = React.useState('')
    const classes = useStyles()

    function goToPickBank(countryCode: string) {
        navigate(`/app/banking/integrations/pick-bank?country_code=${countryCode.toLowerCase()}`)
    }

    return (
        <BanksPaper>
            <Typography variant="overline" component="div" gutterBottom>
                Pick Country
            </Typography>
            <SearchTextField
                onChange={(event) => setSearchValue(event?.target.value.toLowerCase())}
                id="standard-basic"
                label="Search"
                variant="standard"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <List sx={{ overflow: 'auto', maxHeight: 500 }}>
                {Object.values(countries)
                    .filter(
                        (country) =>
                            searchValue.length < 1 ||
                            searchValue === country.code.toLowerCase() ||
                            country.name.toLowerCase().startsWith(searchValue) ||
                            country?.nativeName?.toLowerCase?.()?.startsWith?.(searchValue)
                    )
                    .map((country: countryType) => {
                        return (
                            <StyledListItem disablePadding>
                                <Card className={classes.bankCard} sx={{ display: 'flex' }}>
                                    <ListItemButton onClick={() => goToPickBank(country.code)}>
                                        <CardContent sx={listItemImageSx}>
                                            <Typography
                                                className={classes.countryIcon}
                                                component="div"
                                                variant="body1"
                                            >
                                                {country.emoji}
                                            </Typography>
                                        </CardContent>
                                        <CardContent>
                                            <Box>
                                                <Typography component="div" variant="body1">
                                                    {country.name}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="text.secondary"
                                                    component="div"
                                                >
                                                    {country.nativeName}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </ListItemButton>
                                </Card>
                            </StyledListItem>
                        )
                    })}
            </List>
        </BanksPaper>
    )
}

export default PickBank
