export type countryType = {
    code: string
    unicode: string
    name: string
    nativeName?: string
    emoji: string
}

export const countries: Record<string, countryType> = JSON.parse(`{
    "AC": {
        "code": "AC",
        "unicode": "U+1F1E6 U+1F1E8",
        "name": "Ascension Island",
        "emoji": "\ud83c\udde6\ud83c\udde8"
    },
    "AD": {
        "code": "AD",
        "unicode": "U+1F1E6 U+1F1E9",
        "name": "Andorra",
        "emoji": "\ud83c\udde6\ud83c\udde9",
        "nativeName": "Andorra",
        "latlng": [42.5, 1.5]
    },
    "AE": {
        "code": "AE",
        "unicode": "U+1F1E6 U+1F1EA",
        "name": "United Arab Emirates",
        "emoji": "\ud83c\udde6\ud83c\uddea",
        "nativeName": "\u062f\u0648\u0644\u0629 \u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062a \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629",
        "latlng": [24.0, 54.0]
    },
    "AF": {
        "code": "AF",
        "unicode": "U+1F1E6 U+1F1EB",
        "name": "Afghanistan",
        "emoji": "\ud83c\udde6\ud83c\uddeb",
        "nativeName": "\u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646",
        "latlng": [33.0, 65.0]
    },
    "AG": {
        "code": "AG",
        "unicode": "U+1F1E6 U+1F1EC",
        "name": "Antigua & Barbuda",
        "emoji": "\ud83c\udde6\ud83c\uddec",
        "nativeName": "Antigua and Barbuda",
        "latlng": [17.05, -61.8]
    },
    "AI": {
        "code": "AI",
        "unicode": "U+1F1E6 U+1F1EE",
        "name": "Anguilla",
        "emoji": "\ud83c\udde6\ud83c\uddee",
        "nativeName": "Anguilla",
        "latlng": [18.25, -63.16666666]
    },
    "AL": {
        "code": "AL",
        "unicode": "U+1F1E6 U+1F1F1",
        "name": "Albania",
        "emoji": "\ud83c\udde6\ud83c\uddf1",
        "nativeName": "Shqip\u00ebria",
        "latlng": [41.0, 20.0]
    },
    "AM": {
        "code": "AM",
        "unicode": "U+1F1E6 U+1F1F2",
        "name": "Armenia",
        "emoji": "\ud83c\udde6\ud83c\uddf2",
        "nativeName": "\u0540\u0561\u0575\u0561\u057d\u057f\u0561\u0576",
        "latlng": [40.0, 45.0]
    },
    "AO": {
        "code": "AO",
        "unicode": "U+1F1E6 U+1F1F4",
        "name": "Angola",
        "emoji": "\ud83c\udde6\ud83c\uddf4",
        "nativeName": "Angola",
        "latlng": [-12.5, 18.5]
    },
    "AQ": {
        "code": "AQ",
        "unicode": "U+1F1E6 U+1F1F6",
        "name": "Antarctica",
        "emoji": "\ud83c\udde6\ud83c\uddf6",
        "nativeName": "Antarctica",
        "latlng": [-74.65, 4.48]
    },
    "AR": {
        "code": "AR",
        "unicode": "U+1F1E6 U+1F1F7",
        "name": "Argentina",
        "emoji": "\ud83c\udde6\ud83c\uddf7",
        "nativeName": "Argentina",
        "latlng": [-34.0, -64.0]
    },
    "AS": {
        "code": "AS",
        "unicode": "U+1F1E6 U+1F1F8",
        "name": "American Samoa",
        "emoji": "\ud83c\udde6\ud83c\uddf8",
        "nativeName": "American Samoa",
        "latlng": [-14.33333333, -170.0]
    },
    "AT": {
        "code": "AT",
        "unicode": "U+1F1E6 U+1F1F9",
        "name": "Austria",
        "emoji": "\ud83c\udde6\ud83c\uddf9",
        "nativeName": "\u00d6sterreich",
        "translations": { "DK": "\u00d8strig" },
        "latlng": [47.33333333, 13.33333333]
    },
    "AU": {
        "code": "AU",
        "unicode": "U+1F1E6 U+1F1FA",
        "name": "Australia",
        "emoji": "\ud83c\udde6\ud83c\uddfa",
        "nativeName": "Australia",
        "latlng": [-27.0, 133.0]
    },
    "AW": {
        "code": "AW",
        "unicode": "U+1F1E6 U+1F1FC",
        "name": "Aruba",
        "emoji": "\ud83c\udde6\ud83c\uddfc",
        "nativeName": "Aruba",
        "latlng": [12.5, -69.96666666]
    },
    "AX": {
        "code": "AX",
        "unicode": "U+1F1E6 U+1F1FD",
        "name": "\u00c5land Islands",
        "emoji": "\ud83c\udde6\ud83c\uddfd",
        "nativeName": "\u00c5land",
        "latlng": [60.116667, 19.9]
    },
    "AZ": {
        "code": "AZ",
        "unicode": "U+1F1E6 U+1F1FF",
        "name": "Azerbaijan",
        "emoji": "\ud83c\udde6\ud83c\uddff",
        "nativeName": "Az\u0259rbaycan",
        "latlng": [40.5, 47.5]
    },
    "BA": {
        "code": "BA",
        "unicode": "U+1F1E7 U+1F1E6",
        "name": "Bosnia & Herzegovina",
        "emoji": "\ud83c\udde7\ud83c\udde6",
        "nativeName": "Bosna i Hercegovina",
        "latlng": [44.0, 18.0]
    },
    "BB": {
        "code": "BB",
        "unicode": "U+1F1E7 U+1F1E7",
        "name": "Barbados",
        "emoji": "\ud83c\udde7\ud83c\udde7",
        "nativeName": "Barbados",
        "latlng": [13.16666666, -59.53333333]
    },
    "BD": {
        "code": "BD",
        "unicode": "U+1F1E7 U+1F1E9",
        "name": "Bangladesh",
        "emoji": "\ud83c\udde7\ud83c\udde9",
        "nativeName": "Bangladesh",
        "latlng": [24.0, 90.0]
    },
    "BE": {
        "code": "BE",
        "unicode": "U+1F1E7 U+1F1EA",
        "name": "Belgium",
        "emoji": "\ud83c\udde7\ud83c\uddea",
        "nativeName": "Belgi\u00eb",
        "latlng": [50.83333333, 4.0]
    },
    "BF": {
        "code": "BF",
        "unicode": "U+1F1E7 U+1F1EB",
        "name": "Burkina Faso",
        "emoji": "\ud83c\udde7\ud83c\uddeb",
        "nativeName": "Burkina Faso",
        "latlng": [13.0, -2.0]
    },
    "BG": {
        "code": "BG",
        "unicode": "U+1F1E7 U+1F1EC",
        "name": "Bulgaria",
        "emoji": "\ud83c\udde7\ud83c\uddec",
        "nativeName": "\u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f",
        "latlng": [43.0, 25.0]
    },
    "BH": {
        "code": "BH",
        "unicode": "U+1F1E7 U+1F1ED",
        "name": "Bahrain",
        "emoji": "\ud83c\udde7\ud83c\udded",
        "nativeName": "\u200f\u0627\u0644\u0628\u062d\u0631\u064a\u0646",
        "latlng": [26.0, 50.55]
    },
    "BI": {
        "code": "BI",
        "unicode": "U+1F1E7 U+1F1EE",
        "name": "Burundi",
        "emoji": "\ud83c\udde7\ud83c\uddee",
        "nativeName": "Burundi",
        "latlng": [-3.5, 30.0]
    },
    "BJ": {
        "code": "BJ",
        "unicode": "U+1F1E7 U+1F1EF",
        "name": "Benin",
        "emoji": "\ud83c\udde7\ud83c\uddef",
        "nativeName": "B\u00e9nin",
        "latlng": [9.5, 2.25]
    },
    "BL": {
        "code": "BL",
        "unicode": "U+1F1E7 U+1F1F1",
        "name": "St. Barth\u00e9lemy",
        "emoji": "\ud83c\udde7\ud83c\uddf1",
        "nativeName": "Saint-Barth\u00e9lemy",
        "latlng": [18.5, -63.41666666]
    },
    "BM": {
        "code": "BM",
        "unicode": "U+1F1E7 U+1F1F2",
        "name": "Bermuda",
        "emoji": "\ud83c\udde7\ud83c\uddf2",
        "nativeName": "Bermuda",
        "latlng": [32.33333333, -64.75]
    },
    "BN": {
        "code": "BN",
        "unicode": "U+1F1E7 U+1F1F3",
        "name": "Brunei",
        "emoji": "\ud83c\udde7\ud83c\uddf3",
        "nativeName": "Negara Brunei Darussalam",
        "latlng": [4.5, 114.66666666]
    },
    "BO": {
        "code": "BO",
        "unicode": "U+1F1E7 U+1F1F4",
        "name": "Bolivia",
        "emoji": "\ud83c\udde7\ud83c\uddf4",
        "nativeName": "Bolivia",
        "latlng": [-17.0, -65.0]
    },
    "BQ": {
        "code": "BQ",
        "unicode": "U+1F1E7 U+1F1F6",
        "name": "Caribbean Netherlands",
        "emoji": "\ud83c\udde7\ud83c\uddf6",
        "nativeName": "Bonaire",
        "latlng": [12.15, -68.266667]
    },
    "BR": {
        "code": "BR",
        "unicode": "U+1F1E7 U+1F1F7",
        "name": "Brazil",
        "emoji": "\ud83c\udde7\ud83c\uddf7",
        "nativeName": "Brasil",
        "latlng": [-10.0, -55.0]
    },
    "BS": {
        "code": "BS",
        "unicode": "U+1F1E7 U+1F1F8",
        "name": "Bahamas",
        "emoji": "\ud83c\udde7\ud83c\uddf8",
        "nativeName": "Bahamas",
        "latlng": [24.25, -76.0]
    },
    "BT": {
        "code": "BT",
        "unicode": "U+1F1E7 U+1F1F9",
        "name": "Bhutan",
        "emoji": "\ud83c\udde7\ud83c\uddf9",
        "nativeName": "\u02bcbrug-yul",
        "latlng": [27.5, 90.5]
    },
    "BV": {
        "code": "BV",
        "unicode": "U+1F1E7 U+1F1FB",
        "name": "Bouvet Island",
        "emoji": "\ud83c\udde7\ud83c\uddfb",
        "nativeName": "Bouvet\u00f8ya",
        "latlng": [-54.43333333, 3.4]
    },
    "BW": {
        "code": "BW",
        "unicode": "U+1F1E7 U+1F1FC",
        "name": "Botswana",
        "emoji": "\ud83c\udde7\ud83c\uddfc",
        "nativeName": "Botswana",
        "latlng": [-22.0, 24.0]
    },
    "BY": {
        "code": "BY",
        "unicode": "U+1F1E7 U+1F1FE",
        "name": "Belarus",
        "emoji": "\ud83c\udde7\ud83c\uddfe",
        "nativeName": "\u0411\u0435\u043b\u0430\u0440\u0443\u0301\u0441\u044c",
        "latlng": [53.0, 28.0]
    },
    "BZ": {
        "code": "BZ",
        "unicode": "U+1F1E7 U+1F1FF",
        "name": "Belize",
        "emoji": "\ud83c\udde7\ud83c\uddff",
        "nativeName": "Belize",
        "latlng": [17.25, -88.75]
    },
    "CA": {
        "code": "CA",
        "unicode": "U+1F1E8 U+1F1E6",
        "name": "Canada",
        "emoji": "\ud83c\udde8\ud83c\udde6",
        "nativeName": "Canada",
        "latlng": [60.0, -95.0]
    },
    "CC": {
        "code": "CC",
        "unicode": "U+1F1E8 U+1F1E8",
        "name": "Cocos (Keeling) Islands",
        "emoji": "\ud83c\udde8\ud83c\udde8",
        "nativeName": "Cocos (Keeling) Islands",
        "latlng": [-12.5, 96.83333333]
    },
    "CD": {
        "code": "CD",
        "unicode": "U+1F1E8 U+1F1E9",
        "name": "Congo - Kinshasa",
        "emoji": "\ud83c\udde8\ud83c\udde9",
        "nativeName": "R\u00e9publique d\u00e9mocratique du Congo",
        "latlng": [0.0, 25.0]
    },
    "CF": {
        "code": "CF",
        "unicode": "U+1F1E8 U+1F1EB",
        "name": "Central African Republic",
        "emoji": "\ud83c\udde8\ud83c\uddeb",
        "nativeName": "K\u00f6d\u00f6r\u00f6s\u00ease t\u00ee B\u00eaafr\u00eeka",
        "latlng": [7.0, 21.0]
    },
    "CG": {
        "code": "CG",
        "unicode": "U+1F1E8 U+1F1EC",
        "name": "Congo - Brazzaville",
        "emoji": "\ud83c\udde8\ud83c\uddec",
        "nativeName": "R\u00e9publique du Congo",
        "latlng": [-1.0, 15.0]
    },
    "CH": {
        "code": "CH",
        "unicode": "U+1F1E8 U+1F1ED",
        "name": "Switzerland",
        "emoji": "\ud83c\udde8\ud83c\udded",
        "nativeName": "Schweiz",
        "latlng": [47.0, 8.0]
    },
    "CI": {
        "code": "CI",
        "unicode": "U+1F1E8 U+1F1EE",
        "name": "C\u00f4te d\u2019Ivoire",
        "emoji": "\ud83c\udde8\ud83c\uddee",
        "nativeName": "C\u00f4te d'Ivoire",
        "latlng": [8.0, -5.0]
    },
    "CK": {
        "code": "CK",
        "unicode": "U+1F1E8 U+1F1F0",
        "name": "Cook Islands",
        "emoji": "\ud83c\udde8\ud83c\uddf0",
        "nativeName": "Cook Islands",
        "latlng": [-21.23333333, -159.76666666]
    },
    "CL": {
        "code": "CL",
        "unicode": "U+1F1E8 U+1F1F1",
        "name": "Chile",
        "emoji": "\ud83c\udde8\ud83c\uddf1",
        "nativeName": "Chile",
        "latlng": [-30.0, -71.0]
    },
    "CM": {
        "code": "CM",
        "unicode": "U+1F1E8 U+1F1F2",
        "name": "Cameroon",
        "emoji": "\ud83c\udde8\ud83c\uddf2",
        "nativeName": "Cameroon",
        "latlng": [6.0, 12.0]
    },
    "CN": {
        "code": "CN",
        "unicode": "U+1F1E8 U+1F1F3",
        "name": "China",
        "emoji": "\ud83c\udde8\ud83c\uddf3",
        "nativeName": "\u4e2d\u56fd",
        "latlng": [35.0, 105.0]
    },
    "CO": {
        "code": "CO",
        "unicode": "U+1F1E8 U+1F1F4",
        "name": "Colombia",
        "emoji": "\ud83c\udde8\ud83c\uddf4",
        "nativeName": "Colombia",
        "latlng": [4.0, -72.0]
    },
    "CP": {
        "code": "CP",
        "unicode": "U+1F1E8 U+1F1F5",
        "name": "Clipperton Island",
        "emoji": "\ud83c\udde8\ud83c\uddf5"
    },
    "CR": {
        "code": "CR",
        "unicode": "U+1F1E8 U+1F1F7",
        "name": "Costa Rica",
        "emoji": "\ud83c\udde8\ud83c\uddf7",
        "nativeName": "Costa Rica",
        "latlng": [10.0, -84.0]
    },
    "CU": {
        "code": "CU",
        "unicode": "U+1F1E8 U+1F1FA",
        "name": "Cuba",
        "emoji": "\ud83c\udde8\ud83c\uddfa",
        "nativeName": "Cuba",
        "latlng": [21.5, -80.0]
    },
    "CV": {
        "code": "CV",
        "unicode": "U+1F1E8 U+1F1FB",
        "name": "Cape Verde",
        "emoji": "\ud83c\udde8\ud83c\uddfb",
        "nativeName": "Cabo Verde",
        "latlng": [16.0, -24.0]
    },
    "CW": {
        "code": "CW",
        "unicode": "U+1F1E8 U+1F1FC",
        "name": "Cura\u00e7ao",
        "emoji": "\ud83c\udde8\ud83c\uddfc",
        "nativeName": "Cura\u00e7ao",
        "latlng": [12.116667, -68.933333]
    },
    "CX": {
        "code": "CX",
        "unicode": "U+1F1E8 U+1F1FD",
        "name": "Christmas Island",
        "emoji": "\ud83c\udde8\ud83c\uddfd",
        "nativeName": "Christmas Island",
        "latlng": [-10.5, 105.66666666]
    },
    "CY": {
        "code": "CY",
        "unicode": "U+1F1E8 U+1F1FE",
        "name": "Cyprus",
        "emoji": "\ud83c\udde8\ud83c\uddfe",
        "nativeName": "\u039a\u03cd\u03c0\u03c1\u03bf\u03c2",
        "latlng": [35.0, 33.0]
    },
    "CZ": {
        "code": "CZ",
        "unicode": "U+1F1E8 U+1F1FF",
        "name": "Czechia",
        "emoji": "\ud83c\udde8\ud83c\uddff",
        "nativeName": "\u010cesk\u00e1 republika",
        "latlng": [49.75, 15.5]
    },
    "DE": {
        "code": "DE",
        "unicode": "U+1F1E9 U+1F1EA",
        "name": "Germany",
        "emoji": "\ud83c\udde9\ud83c\uddea",
        "nativeName": "Deutschland",
        "translations": { "DK": "Tyskland" },
        "latlng": [51.0, 9.0]
    },
    "DG": {
        "code": "DG",
        "unicode": "U+1F1E9 U+1F1EC",
        "name": "Diego Garcia",
        "emoji": "\ud83c\udde9\ud83c\uddec"
    },
    "DJ": {
        "code": "DJ",
        "unicode": "U+1F1E9 U+1F1EF",
        "name": "Djibouti",
        "emoji": "\ud83c\udde9\ud83c\uddef",
        "nativeName": "Djibouti",
        "latlng": [11.5, 43.0]
    },
    "DK": {
        "code": "DK",
        "unicode": "U+1F1E9 U+1F1F0",
        "name": "Denmark",
        "emoji": "\ud83c\udde9\ud83c\uddf0",
        "nativeName": "Danmark",
        "latlng": [56.0, 10.0]
    },
    "DM": {
        "code": "DM",
        "unicode": "U+1F1E9 U+1F1F2",
        "name": "Dominica",
        "emoji": "\ud83c\udde9\ud83c\uddf2",
        "nativeName": "Dominica",
        "latlng": [15.41666666, -61.33333333]
    },
    "DO": {
        "code": "DO",
        "unicode": "U+1F1E9 U+1F1F4",
        "name": "Dominican Republic",
        "emoji": "\ud83c\udde9\ud83c\uddf4",
        "nativeName": "Rep\u00fablica Dominicana",
        "latlng": [19.0, -70.66666666]
    },
    "DZ": {
        "code": "DZ",
        "unicode": "U+1F1E9 U+1F1FF",
        "name": "Algeria",
        "emoji": "\ud83c\udde9\ud83c\uddff",
        "nativeName": "\u0627\u0644\u062c\u0632\u0627\u0626\u0631",
        "latlng": [28.0, 3.0]
    },
    "EA": {
        "code": "EA",
        "unicode": "U+1F1EA U+1F1E6",
        "name": "Ceuta & Melilla",
        "emoji": "\ud83c\uddea\ud83c\udde6"
    },
    "EC": {
        "code": "EC",
        "unicode": "U+1F1EA U+1F1E8",
        "name": "Ecuador",
        "emoji": "\ud83c\uddea\ud83c\udde8",
        "nativeName": "Ecuador",
        "latlng": [-2.0, -77.5]
    },
    "EE": {
        "code": "EE",
        "unicode": "U+1F1EA U+1F1EA",
        "name": "Estonia",
        "emoji": "\ud83c\uddea\ud83c\uddea",
        "nativeName": "Eesti",
        "latlng": [59.0, 26.0]
    },
    "EG": {
        "code": "EG",
        "unicode": "U+1F1EA U+1F1EC",
        "name": "Egypt",
        "emoji": "\ud83c\uddea\ud83c\uddec",
        "nativeName": "\u0645\u0635\u0631\u200e",
        "latlng": [27.0, 30.0]
    },
    "EH": {
        "code": "EH",
        "unicode": "U+1F1EA U+1F1ED",
        "name": "Western Sahara",
        "emoji": "\ud83c\uddea\ud83c\udded",
        "nativeName": "\u0627\u0644\u0635\u062d\u0631\u0627\u0621 \u0627\u0644\u063a\u0631\u0628\u064a\u0629",
        "latlng": [24.5, -13.0]
    },
    "ER": {
        "code": "ER",
        "unicode": "U+1F1EA U+1F1F7",
        "name": "Eritrea",
        "emoji": "\ud83c\uddea\ud83c\uddf7",
        "nativeName": "\u12a4\u122d\u1275\u122b",
        "latlng": [15.0, 39.0]
    },
    "ES": {
        "code": "ES",
        "unicode": "U+1F1EA U+1F1F8",
        "name": "Spain",
        "emoji": "\ud83c\uddea\ud83c\uddf8",
        "nativeName": "Espa\u00f1a",
        "translations": { "DK": "Spanien" },
        "latlng": [40.0, -4.0]
    },
    "ET": {
        "code": "ET",
        "unicode": "U+1F1EA U+1F1F9",
        "name": "Ethiopia",
        "emoji": "\ud83c\uddea\ud83c\uddf9",
        "nativeName": "\u12a2\u1275\u12ee\u1335\u12eb",
        "latlng": [8.0, 38.0]
    },
    "EU": {
        "code": "EU",
        "unicode": "U+1F1EA U+1F1FA",
        "name": "European Union",
        "emoji": "\ud83c\uddea\ud83c\uddfa"
    },
    "FI": {
        "code": "FI",
        "unicode": "U+1F1EB U+1F1EE",
        "name": "Finland",
        "emoji": "\ud83c\uddeb\ud83c\uddee",
        "nativeName": "Suomi",
        "latlng": [64.0, 26.0]
    },
    "FJ": {
        "code": "FJ",
        "unicode": "U+1F1EB U+1F1EF",
        "name": "Fiji",
        "emoji": "\ud83c\uddeb\ud83c\uddef",
        "nativeName": "Fiji",
        "latlng": [-18.0, 175.0]
    },
    "FK": {
        "code": "FK",
        "unicode": "U+1F1EB U+1F1F0",
        "name": "Falkland Islands",
        "emoji": "\ud83c\uddeb\ud83c\uddf0",
        "nativeName": "Falkland Islands",
        "latlng": [-51.75, -59.0]
    },
    "FM": {
        "code": "FM",
        "unicode": "U+1F1EB U+1F1F2",
        "name": "Micronesia",
        "emoji": "\ud83c\uddeb\ud83c\uddf2",
        "nativeName": "Micronesia",
        "latlng": [6.91666666, 158.25]
    },
    "FO": {
        "code": "FO",
        "unicode": "U+1F1EB U+1F1F4",
        "name": "Faroe Islands",
        "emoji": "\ud83c\uddeb\ud83c\uddf4",
        "nativeName": "F\u00f8royar",
        "latlng": [62.0, -7.0]
    },
    "FR": {
        "code": "FR",
        "unicode": "U+1F1EB U+1F1F7",
        "name": "France",
        "emoji": "\ud83c\uddeb\ud83c\uddf7",
        "nativeName": "France",
        "latlng": [46.0, 2.0]
    },
    "GA": {
        "code": "GA",
        "unicode": "U+1F1EC U+1F1E6",
        "name": "Gabon",
        "emoji": "\ud83c\uddec\ud83c\udde6",
        "nativeName": "Gabon",
        "latlng": [-1.0, 11.75]
    },
    "GB": {
        "code": "GB",
        "unicode": "U+1F1EC U+1F1E7",
        "name": "United Kingdom",
        "emoji": "\ud83c\uddec\ud83c\udde7",
        "nativeName": "United Kingdom",
        "translations": { "DK": "England" },
        "latlng": [54.0, -2.0]
    },
    "GD": {
        "code": "GD",
        "unicode": "U+1F1EC U+1F1E9",
        "name": "Grenada",
        "emoji": "\ud83c\uddec\ud83c\udde9",
        "nativeName": "Grenada",
        "latlng": [12.11666666, -61.66666666]
    },
    "GE": {
        "code": "GE",
        "unicode": "U+1F1EC U+1F1EA",
        "name": "Georgia",
        "emoji": "\ud83c\uddec\ud83c\uddea",
        "nativeName": "\u10e1\u10d0\u10e5\u10d0\u10e0\u10d7\u10d5\u10d4\u10da\u10dd",
        "latlng": [42.0, 43.5]
    },
    "GF": {
        "code": "GF",
        "unicode": "U+1F1EC U+1F1EB",
        "name": "French Guiana",
        "emoji": "\ud83c\uddec\ud83c\uddeb",
        "nativeName": "Guyane fran\u00e7aise",
        "latlng": [4.0, -53.0]
    },
    "GG": {
        "code": "GG",
        "unicode": "U+1F1EC U+1F1EC",
        "name": "Guernsey",
        "emoji": "\ud83c\uddec\ud83c\uddec",
        "nativeName": "Guernsey",
        "latlng": [49.46666666, -2.58333333]
    },
    "GH": {
        "code": "GH",
        "unicode": "U+1F1EC U+1F1ED",
        "name": "Ghana",
        "emoji": "\ud83c\uddec\ud83c\udded",
        "nativeName": "Ghana",
        "latlng": [8.0, -2.0]
    },
    "GI": {
        "code": "GI",
        "unicode": "U+1F1EC U+1F1EE",
        "name": "Gibraltar",
        "emoji": "\ud83c\uddec\ud83c\uddee",
        "nativeName": "Gibraltar",
        "latlng": [36.13333333, -5.35]
    },
    "GL": {
        "code": "GL",
        "unicode": "U+1F1EC U+1F1F1",
        "name": "Greenland",
        "emoji": "\ud83c\uddec\ud83c\uddf1",
        "nativeName": "Kalaallit Nunaat",
        "latlng": [72.0, -40.0]
    },
    "GM": {
        "code": "GM",
        "unicode": "U+1F1EC U+1F1F2",
        "name": "Gambia",
        "emoji": "\ud83c\uddec\ud83c\uddf2",
        "nativeName": "Gambia",
        "latlng": [13.46666666, -16.56666666]
    },
    "GN": {
        "code": "GN",
        "unicode": "U+1F1EC U+1F1F3",
        "name": "Guinea",
        "emoji": "\ud83c\uddec\ud83c\uddf3",
        "nativeName": "Guin\u00e9e",
        "latlng": [11.0, -10.0]
    },
    "GP": {
        "code": "GP",
        "unicode": "U+1F1EC U+1F1F5",
        "name": "Guadeloupe",
        "emoji": "\ud83c\uddec\ud83c\uddf5",
        "nativeName": "Guadeloupe",
        "latlng": [16.25, -61.583333]
    },
    "GQ": {
        "code": "GQ",
        "unicode": "U+1F1EC U+1F1F6",
        "name": "Equatorial Guinea",
        "emoji": "\ud83c\uddec\ud83c\uddf6",
        "nativeName": "Guinea Ecuatorial",
        "latlng": [2.0, 10.0]
    },
    "GR": {
        "code": "GR",
        "unicode": "U+1F1EC U+1F1F7",
        "name": "Greece",
        "emoji": "\ud83c\uddec\ud83c\uddf7",
        "nativeName": "\u0395\u03bb\u03bb\u03ac\u03b4\u03b1",
        "latlng": [39.0, 22.0]
    },
    "GS": {
        "code": "GS",
        "unicode": "U+1F1EC U+1F1F8",
        "name": "South Georgia & South Sandwich Islands",
        "emoji": "\ud83c\uddec\ud83c\uddf8",
        "nativeName": "South Georgia",
        "latlng": [-54.5, -37.0]
    },
    "GT": {
        "code": "GT",
        "unicode": "U+1F1EC U+1F1F9",
        "name": "Guatemala",
        "emoji": "\ud83c\uddec\ud83c\uddf9",
        "nativeName": "Guatemala",
        "latlng": [15.5, -90.25]
    },
    "GU": {
        "code": "GU",
        "unicode": "U+1F1EC U+1F1FA",
        "name": "Guam",
        "emoji": "\ud83c\uddec\ud83c\uddfa",
        "nativeName": "Guam",
        "latlng": [13.46666666, 144.78333333]
    },
    "GW": {
        "code": "GW",
        "unicode": "U+1F1EC U+1F1FC",
        "name": "Guinea-Bissau",
        "emoji": "\ud83c\uddec\ud83c\uddfc",
        "nativeName": "Guin\u00e9-Bissau",
        "latlng": [12.0, -15.0]
    },
    "GY": {
        "code": "GY",
        "unicode": "U+1F1EC U+1F1FE",
        "name": "Guyana",
        "emoji": "\ud83c\uddec\ud83c\uddfe",
        "nativeName": "Guyana",
        "latlng": [5.0, -59.0]
    },
    "HK": {
        "code": "HK",
        "unicode": "U+1F1ED U+1F1F0",
        "name": "Hong Kong SAR China",
        "emoji": "\ud83c\udded\ud83c\uddf0",
        "nativeName": "\u9999\u6e2f",
        "latlng": [22.25, 114.16666666]
    },
    "HM": {
        "code": "HM",
        "unicode": "U+1F1ED U+1F1F2",
        "name": "Heard & McDonald Islands",
        "emoji": "\ud83c\udded\ud83c\uddf2",
        "nativeName": "Heard Island and McDonald Islands",
        "latlng": [-53.1, 72.51666666]
    },
    "HN": {
        "code": "HN",
        "unicode": "U+1F1ED U+1F1F3",
        "name": "Honduras",
        "emoji": "\ud83c\udded\ud83c\uddf3",
        "nativeName": "Honduras",
        "latlng": [15.0, -86.5]
    },
    "HR": {
        "code": "HR",
        "unicode": "U+1F1ED U+1F1F7",
        "name": "Croatia",
        "emoji": "\ud83c\udded\ud83c\uddf7",
        "nativeName": "Hrvatska",
        "latlng": [45.16666666, 15.5]
    },
    "HT": {
        "code": "HT",
        "unicode": "U+1F1ED U+1F1F9",
        "name": "Haiti",
        "emoji": "\ud83c\udded\ud83c\uddf9",
        "nativeName": "Ha\u00efti",
        "latlng": [19.0, -72.41666666]
    },
    "HU": {
        "code": "HU",
        "unicode": "U+1F1ED U+1F1FA",
        "name": "Hungary",
        "emoji": "\ud83c\udded\ud83c\uddfa",
        "nativeName": "Magyarorsz\u00e1g",
        "latlng": [47.0, 20.0]
    },
    "IC": {
        "code": "IC",
        "unicode": "U+1F1EE U+1F1E8",
        "name": "Canary Islands",
        "emoji": "\ud83c\uddee\ud83c\udde8"
    },
    "ID": {
        "code": "ID",
        "unicode": "U+1F1EE U+1F1E9",
        "name": "Indonesia",
        "emoji": "\ud83c\uddee\ud83c\udde9",
        "nativeName": "Indonesia",
        "latlng": [-5.0, 120.0]
    },
    "IE": {
        "code": "IE",
        "unicode": "U+1F1EE U+1F1EA",
        "name": "Ireland",
        "emoji": "\ud83c\uddee\ud83c\uddea",
        "nativeName": "\u00c9ire",
        "latlng": [53.0, -8.0]
    },
    "IL": {
        "code": "IL",
        "unicode": "U+1F1EE U+1F1F1",
        "name": "Israel",
        "emoji": "\ud83c\uddee\ud83c\uddf1",
        "nativeName": "\u05d9\u05b4\u05e9\u05b0\u05c2\u05e8\u05b8\u05d0\u05b5\u05dc",
        "latlng": [31.5, 34.75]
    },
    "IM": {
        "code": "IM",
        "unicode": "U+1F1EE U+1F1F2",
        "name": "Isle of Man",
        "emoji": "\ud83c\uddee\ud83c\uddf2",
        "nativeName": "Isle of Man",
        "latlng": [54.25, -4.5]
    },
    "IN": {
        "code": "IN",
        "unicode": "U+1F1EE U+1F1F3",
        "name": "India",
        "emoji": "\ud83c\uddee\ud83c\uddf3",
        "nativeName": "\u092d\u093e\u0930\u0924",
        "latlng": [20.0, 77.0]
    },
    "IO": {
        "code": "IO",
        "unicode": "U+1F1EE U+1F1F4",
        "name": "British Indian Ocean Territory",
        "emoji": "\ud83c\uddee\ud83c\uddf4",
        "nativeName": "British Indian Ocean Territory",
        "latlng": [-6.0, 71.5]
    },
    "IQ": {
        "code": "IQ",
        "unicode": "U+1F1EE U+1F1F6",
        "name": "Iraq",
        "emoji": "\ud83c\uddee\ud83c\uddf6",
        "nativeName": "\u0627\u0644\u0639\u0631\u0627\u0642",
        "latlng": [33.0, 44.0]
    },
    "IR": {
        "code": "IR",
        "unicode": "U+1F1EE U+1F1F7",
        "name": "Iran",
        "emoji": "\ud83c\uddee\ud83c\uddf7",
        "nativeName": "\u0627\u06cc\u0631\u0627\u0646",
        "latlng": [32.0, 53.0]
    },
    "IS": {
        "code": "IS",
        "unicode": "U+1F1EE U+1F1F8",
        "name": "Iceland",
        "emoji": "\ud83c\uddee\ud83c\uddf8",
        "nativeName": "\u00cdsland",
        "latlng": [65.0, -18.0]
    },
    "IT": {
        "code": "IT",
        "unicode": "U+1F1EE U+1F1F9",
        "name": "Italy",
        "emoji": "\ud83c\uddee\ud83c\uddf9",
        "nativeName": "Italia",
        "translations": { "DK": "Italien" },
        "latlng": [42.83333333, 12.83333333]
    },
    "JE": {
        "code": "JE",
        "unicode": "U+1F1EF U+1F1EA",
        "name": "Jersey",
        "emoji": "\ud83c\uddef\ud83c\uddea",
        "nativeName": "Jersey",
        "latlng": [49.25, -2.16666666]
    },
    "JM": {
        "code": "JM",
        "unicode": "U+1F1EF U+1F1F2",
        "name": "Jamaica",
        "emoji": "\ud83c\uddef\ud83c\uddf2",
        "nativeName": "Jamaica",
        "latlng": [18.25, -77.5]
    },
    "JO": {
        "code": "JO",
        "unicode": "U+1F1EF U+1F1F4",
        "name": "Jordan",
        "emoji": "\ud83c\uddef\ud83c\uddf4",
        "nativeName": "\u0627\u0644\u0623\u0631\u062f\u0646",
        "latlng": [31.0, 36.0]
    },
    "JP": {
        "code": "JP",
        "unicode": "U+1F1EF U+1F1F5",
        "name": "Japan",
        "emoji": "\ud83c\uddef\ud83c\uddf5",
        "nativeName": "\u65e5\u672c",
        "latlng": [36.0, 138.0]
    },
    "KE": {
        "code": "KE",
        "unicode": "U+1F1F0 U+1F1EA",
        "name": "Kenya",
        "emoji": "\ud83c\uddf0\ud83c\uddea",
        "nativeName": "Kenya",
        "latlng": [1.0, 38.0]
    },
    "KG": {
        "code": "KG",
        "unicode": "U+1F1F0 U+1F1EC",
        "name": "Kyrgyzstan",
        "emoji": "\ud83c\uddf0\ud83c\uddec",
        "nativeName": "\u041a\u044b\u0440\u0433\u044b\u0437\u0441\u0442\u0430\u043d",
        "latlng": [41.0, 75.0]
    },
    "KH": {
        "code": "KH",
        "unicode": "U+1F1F0 U+1F1ED",
        "name": "Cambodia",
        "emoji": "\ud83c\uddf0\ud83c\udded",
        "nativeName": "K\u00e2mp\u016dch\u00e9a",
        "latlng": [13.0, 105.0]
    },
    "KI": {
        "code": "KI",
        "unicode": "U+1F1F0 U+1F1EE",
        "name": "Kiribati",
        "emoji": "\ud83c\uddf0\ud83c\uddee",
        "nativeName": "Kiribati",
        "latlng": [1.41666666, 173.0]
    },
    "KM": {
        "code": "KM",
        "unicode": "U+1F1F0 U+1F1F2",
        "name": "Comoros",
        "emoji": "\ud83c\uddf0\ud83c\uddf2",
        "nativeName": "Komori",
        "latlng": [-12.16666666, 44.25]
    },
    "KN": {
        "code": "KN",
        "unicode": "U+1F1F0 U+1F1F3",
        "name": "St. Kitts & Nevis",
        "emoji": "\ud83c\uddf0\ud83c\uddf3",
        "nativeName": "Saint Kitts and Nevis",
        "latlng": [17.33333333, -62.75]
    },
    "KP": {
        "code": "KP",
        "unicode": "U+1F1F0 U+1F1F5",
        "name": "North Korea",
        "emoji": "\ud83c\uddf0\ud83c\uddf5",
        "nativeName": "\ubd81\ud55c",
        "latlng": [40.0, 127.0]
    },
    "KR": {
        "code": "KR",
        "unicode": "U+1F1F0 U+1F1F7",
        "name": "South Korea",
        "emoji": "\ud83c\uddf0\ud83c\uddf7",
        "nativeName": "\ub300\ud55c\ubbfc\uad6d",
        "latlng": [37.0, 127.5]
    },
    "KW": {
        "code": "KW",
        "unicode": "U+1F1F0 U+1F1FC",
        "name": "Kuwait",
        "emoji": "\ud83c\uddf0\ud83c\uddfc",
        "nativeName": "\u0627\u0644\u0643\u0648\u064a\u062a",
        "latlng": [29.5, 45.75]
    },
    "KY": {
        "code": "KY",
        "unicode": "U+1F1F0 U+1F1FE",
        "name": "Cayman Islands",
        "emoji": "\ud83c\uddf0\ud83c\uddfe",
        "nativeName": "Cayman Islands",
        "latlng": [19.5, -80.5]
    },
    "KZ": {
        "code": "KZ",
        "unicode": "U+1F1F0 U+1F1FF",
        "name": "Kazakhstan",
        "emoji": "\ud83c\uddf0\ud83c\uddff",
        "nativeName": "\u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d",
        "latlng": [48.0, 68.0]
    },
    "LA": {
        "code": "LA",
        "unicode": "U+1F1F1 U+1F1E6",
        "name": "Laos",
        "emoji": "\ud83c\uddf1\ud83c\udde6",
        "nativeName": "\u0eaa\u0e9b\u0e9b\u0ea5\u0eb2\u0ea7",
        "latlng": [18.0, 105.0]
    },
    "LB": {
        "code": "LB",
        "unicode": "U+1F1F1 U+1F1E7",
        "name": "Lebanon",
        "emoji": "\ud83c\uddf1\ud83c\udde7",
        "nativeName": "\u0644\u0628\u0646\u0627\u0646",
        "latlng": [33.83333333, 35.83333333]
    },
    "LC": {
        "code": "LC",
        "unicode": "U+1F1F1 U+1F1E8",
        "name": "St. Lucia",
        "emoji": "\ud83c\uddf1\ud83c\udde8",
        "nativeName": "Saint Lucia",
        "latlng": [13.88333333, -60.96666666]
    },
    "LI": {
        "code": "LI",
        "unicode": "U+1F1F1 U+1F1EE",
        "name": "Liechtenstein",
        "emoji": "\ud83c\uddf1\ud83c\uddee",
        "nativeName": "Liechtenstein",
        "latlng": [47.26666666, 9.53333333]
    },
    "LK": {
        "code": "LK",
        "unicode": "U+1F1F1 U+1F1F0",
        "name": "Sri Lanka",
        "emoji": "\ud83c\uddf1\ud83c\uddf0",
        "nativeName": "\u015br\u012b la\u1e43k\u0101va",
        "latlng": [7.0, 81.0]
    },
    "LR": {
        "code": "LR",
        "unicode": "U+1F1F1 U+1F1F7",
        "name": "Liberia",
        "emoji": "\ud83c\uddf1\ud83c\uddf7",
        "nativeName": "Liberia",
        "latlng": [6.5, -9.5]
    },
    "LS": {
        "code": "LS",
        "unicode": "U+1F1F1 U+1F1F8",
        "name": "Lesotho",
        "emoji": "\ud83c\uddf1\ud83c\uddf8",
        "nativeName": "Lesotho",
        "latlng": [-29.5, 28.5]
    },
    "LT": {
        "code": "LT",
        "unicode": "U+1F1F1 U+1F1F9",
        "name": "Lithuania",
        "emoji": "\ud83c\uddf1\ud83c\uddf9",
        "nativeName": "Lietuva",
        "latlng": [56.0, 24.0]
    },
    "LU": {
        "code": "LU",
        "unicode": "U+1F1F1 U+1F1FA",
        "name": "Luxembourg",
        "emoji": "\ud83c\uddf1\ud83c\uddfa",
        "nativeName": "Luxembourg",
        "latlng": [49.75, 6.16666666]
    },
    "LV": {
        "code": "LV",
        "unicode": "U+1F1F1 U+1F1FB",
        "name": "Latvia",
        "emoji": "\ud83c\uddf1\ud83c\uddfb",
        "nativeName": "Latvija",
        "latlng": [57.0, 25.0]
    },
    "LY": {
        "code": "LY",
        "unicode": "U+1F1F1 U+1F1FE",
        "name": "Libya",
        "emoji": "\ud83c\uddf1\ud83c\uddfe",
        "nativeName": "\u200f\u0644\u064a\u0628\u064a\u0627",
        "latlng": [25.0, 17.0]
    },
    "MA": {
        "code": "MA",
        "unicode": "U+1F1F2 U+1F1E6",
        "name": "Morocco",
        "emoji": "\ud83c\uddf2\ud83c\udde6",
        "nativeName": "\u0627\u0644\u0645\u063a\u0631\u0628",
        "latlng": [32.0, -5.0]
    },
    "MC": {
        "code": "MC",
        "unicode": "U+1F1F2 U+1F1E8",
        "name": "Monaco",
        "emoji": "\ud83c\uddf2\ud83c\udde8",
        "nativeName": "Monaco",
        "latlng": [43.73333333, 7.4]
    },
    "MD": {
        "code": "MD",
        "unicode": "U+1F1F2 U+1F1E9",
        "name": "Moldova",
        "emoji": "\ud83c\uddf2\ud83c\udde9",
        "nativeName": "Moldova",
        "latlng": [47.0, 29.0]
    },
    "ME": {
        "code": "ME",
        "unicode": "U+1F1F2 U+1F1EA",
        "name": "Montenegro",
        "emoji": "\ud83c\uddf2\ud83c\uddea",
        "nativeName": "\u0426\u0440\u043d\u0430 \u0413\u043e\u0440\u0430",
        "latlng": [42.5, 19.3]
    },
    "MF": {
        "code": "MF",
        "unicode": "U+1F1F2 U+1F1EB",
        "name": "St. Martin",
        "emoji": "\ud83c\uddf2\ud83c\uddeb",
        "nativeName": "Saint-Martin",
        "latlng": [18.08333333, -63.95]
    },
    "MG": {
        "code": "MG",
        "unicode": "U+1F1F2 U+1F1EC",
        "name": "Madagascar",
        "emoji": "\ud83c\uddf2\ud83c\uddec",
        "nativeName": "Madagasikara",
        "latlng": [-20.0, 47.0]
    },
    "MH": {
        "code": "MH",
        "unicode": "U+1F1F2 U+1F1ED",
        "name": "Marshall Islands",
        "emoji": "\ud83c\uddf2\ud83c\udded",
        "nativeName": "M\u0327aje\u013c",
        "latlng": [9.0, 168.0]
    },
    "MK": {
        "code": "MK",
        "unicode": "U+1F1F2 U+1F1F0",
        "name": "Macedonia",
        "emoji": "\ud83c\uddf2\ud83c\uddf0",
        "nativeName": "\u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430",
        "latlng": [41.83333333, 22.0]
    },
    "ML": {
        "code": "ML",
        "unicode": "U+1F1F2 U+1F1F1",
        "name": "Mali",
        "emoji": "\ud83c\uddf2\ud83c\uddf1",
        "nativeName": "Mali",
        "latlng": [17.0, -4.0]
    },
    "MM": {
        "code": "MM",
        "unicode": "U+1F1F2 U+1F1F2",
        "name": "Myanmar (Burma)",
        "emoji": "\ud83c\uddf2\ud83c\uddf2",
        "nativeName": "Myanma",
        "latlng": [22.0, 98.0]
    },
    "MN": {
        "code": "MN",
        "unicode": "U+1F1F2 U+1F1F3",
        "name": "Mongolia",
        "emoji": "\ud83c\uddf2\ud83c\uddf3",
        "nativeName": "\u041c\u043e\u043d\u0433\u043e\u043b \u0443\u043b\u0441",
        "latlng": [46.0, 105.0]
    },
    "MO": {
        "code": "MO",
        "unicode": "U+1F1F2 U+1F1F4",
        "name": "Macau SAR China",
        "emoji": "\ud83c\uddf2\ud83c\uddf4",
        "nativeName": "\u6fb3\u9580",
        "latlng": [22.16666666, 113.55]
    },
    "MP": {
        "code": "MP",
        "unicode": "U+1F1F2 U+1F1F5",
        "name": "Northern Mariana Islands",
        "emoji": "\ud83c\uddf2\ud83c\uddf5",
        "nativeName": "Northern Mariana Islands",
        "latlng": [15.2, 145.75]
    },
    "MQ": {
        "code": "MQ",
        "unicode": "U+1F1F2 U+1F1F6",
        "name": "Martinique",
        "emoji": "\ud83c\uddf2\ud83c\uddf6",
        "nativeName": "Martinique",
        "latlng": [14.666667, -61.0]
    },
    "MR": {
        "code": "MR",
        "unicode": "U+1F1F2 U+1F1F7",
        "name": "Mauritania",
        "emoji": "\ud83c\uddf2\ud83c\uddf7",
        "nativeName": "\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0627",
        "latlng": [20.0, -12.0]
    },
    "MS": {
        "code": "MS",
        "unicode": "U+1F1F2 U+1F1F8",
        "name": "Montserrat",
        "emoji": "\ud83c\uddf2\ud83c\uddf8",
        "nativeName": "Montserrat",
        "latlng": [16.75, -62.2]
    },
    "MT": {
        "code": "MT",
        "unicode": "U+1F1F2 U+1F1F9",
        "name": "Malta",
        "emoji": "\ud83c\uddf2\ud83c\uddf9",
        "nativeName": "Malta",
        "latlng": [35.83333333, 14.58333333]
    },
    "MU": {
        "code": "MU",
        "unicode": "U+1F1F2 U+1F1FA",
        "name": "Mauritius",
        "emoji": "\ud83c\uddf2\ud83c\uddfa",
        "nativeName": "Maurice",
        "latlng": [-20.28333333, 57.55]
    },
    "MV": {
        "code": "MV",
        "unicode": "U+1F1F2 U+1F1FB",
        "name": "Maldives",
        "emoji": "\ud83c\uddf2\ud83c\uddfb",
        "nativeName": "Maldives",
        "latlng": [3.25, 73.0]
    },
    "MW": {
        "code": "MW",
        "unicode": "U+1F1F2 U+1F1FC",
        "name": "Malawi",
        "emoji": "\ud83c\uddf2\ud83c\uddfc",
        "nativeName": "Malawi",
        "latlng": [-13.5, 34.0]
    },
    "MX": {
        "code": "MX",
        "unicode": "U+1F1F2 U+1F1FD",
        "name": "Mexico",
        "emoji": "\ud83c\uddf2\ud83c\uddfd",
        "nativeName": "M\u00e9xico",
        "latlng": [23.0, -102.0]
    },
    "MY": {
        "code": "MY",
        "unicode": "U+1F1F2 U+1F1FE",
        "name": "Malaysia",
        "emoji": "\ud83c\uddf2\ud83c\uddfe",
        "nativeName": "Malaysia",
        "latlng": [2.5, 112.5]
    },
    "MZ": {
        "code": "MZ",
        "unicode": "U+1F1F2 U+1F1FF",
        "name": "Mozambique",
        "emoji": "\ud83c\uddf2\ud83c\uddff",
        "nativeName": "Mo\u00e7ambique",
        "latlng": [-18.25, 35.0]
    },
    "NA": {
        "code": "NA",
        "unicode": "U+1F1F3 U+1F1E6",
        "name": "Namibia",
        "emoji": "\ud83c\uddf3\ud83c\udde6",
        "nativeName": "Namibia",
        "latlng": [-22.0, 17.0]
    },
    "NC": {
        "code": "NC",
        "unicode": "U+1F1F3 U+1F1E8",
        "name": "New Caledonia",
        "emoji": "\ud83c\uddf3\ud83c\udde8",
        "nativeName": "Nouvelle-Cal\u00e9donie",
        "latlng": [-21.5, 165.5]
    },
    "NE": {
        "code": "NE",
        "unicode": "U+1F1F3 U+1F1EA",
        "name": "Niger",
        "emoji": "\ud83c\uddf3\ud83c\uddea",
        "nativeName": "Niger",
        "latlng": [16.0, 8.0]
    },
    "NF": {
        "code": "NF",
        "unicode": "U+1F1F3 U+1F1EB",
        "name": "Norfolk Island",
        "emoji": "\ud83c\uddf3\ud83c\uddeb",
        "nativeName": "Norfolk Island",
        "latlng": [-29.03333333, 167.95]
    },
    "NG": {
        "code": "NG",
        "unicode": "U+1F1F3 U+1F1EC",
        "name": "Nigeria",
        "emoji": "\ud83c\uddf3\ud83c\uddec",
        "nativeName": "Nigeria",
        "latlng": [10.0, 8.0]
    },
    "NI": {
        "code": "NI",
        "unicode": "U+1F1F3 U+1F1EE",
        "name": "Nicaragua",
        "emoji": "\ud83c\uddf3\ud83c\uddee",
        "nativeName": "Nicaragua",
        "latlng": [13.0, -85.0]
    },
    "NL": {
        "code": "NL",
        "unicode": "U+1F1F3 U+1F1F1",
        "name": "Netherlands",
        "emoji": "\ud83c\uddf3\ud83c\uddf1",
        "nativeName": "Nederland",
        "translations": { "DK": "Holland" },
        "latlng": [52.5, 5.75]
    },
    "NO": {
        "code": "NO",
        "unicode": "U+1F1F3 U+1F1F4",
        "name": "Norway",
        "emoji": "\ud83c\uddf3\ud83c\uddf4",
        "nativeName": "Norge",
        "latlng": [62.0, 10.0]
    },
    "NP": {
        "code": "NP",
        "unicode": "U+1F1F3 U+1F1F5",
        "name": "Nepal",
        "emoji": "\ud83c\uddf3\ud83c\uddf5",
        "nativeName": "\u0928\u0947\u092a\u093e\u0932",
        "latlng": [28.0, 84.0]
    },
    "NR": {
        "code": "NR",
        "unicode": "U+1F1F3 U+1F1F7",
        "name": "Nauru",
        "emoji": "\ud83c\uddf3\ud83c\uddf7",
        "nativeName": "Nauru",
        "latlng": [-0.53333333, 166.91666666]
    },
    "NU": {
        "code": "NU",
        "unicode": "U+1F1F3 U+1F1FA",
        "name": "Niue",
        "emoji": "\ud83c\uddf3\ud83c\uddfa",
        "nativeName": "Niu\u0113",
        "latlng": [-19.03333333, -169.86666666]
    },
    "NZ": {
        "code": "NZ",
        "unicode": "U+1F1F3 U+1F1FF",
        "name": "New Zealand",
        "emoji": "\ud83c\uddf3\ud83c\uddff",
        "nativeName": "New Zealand",
        "latlng": [-41.0, 174.0]
    },
    "OM": {
        "code": "OM",
        "unicode": "U+1F1F4 U+1F1F2",
        "name": "Oman",
        "emoji": "\ud83c\uddf4\ud83c\uddf2",
        "nativeName": "\u0639\u0645\u0627\u0646",
        "latlng": [21.0, 57.0]
    },
    "PA": {
        "code": "PA",
        "unicode": "U+1F1F5 U+1F1E6",
        "name": "Panama",
        "emoji": "\ud83c\uddf5\ud83c\udde6",
        "nativeName": "Panam\u00e1",
        "latlng": [9.0, -80.0]
    },
    "PE": {
        "code": "PE",
        "unicode": "U+1F1F5 U+1F1EA",
        "name": "Peru",
        "emoji": "\ud83c\uddf5\ud83c\uddea",
        "nativeName": "Per\u00fa",
        "latlng": [-10.0, -76.0]
    },
    "PF": {
        "code": "PF",
        "unicode": "U+1F1F5 U+1F1EB",
        "name": "French Polynesia",
        "emoji": "\ud83c\uddf5\ud83c\uddeb",
        "nativeName": "Polyn\u00e9sie fran\u00e7aise",
        "latlng": [-15.0, -140.0]
    },
    "PG": {
        "code": "PG",
        "unicode": "U+1F1F5 U+1F1EC",
        "name": "Papua New Guinea",
        "emoji": "\ud83c\uddf5\ud83c\uddec",
        "nativeName": "Papua Niugini",
        "latlng": [-6.0, 147.0]
    },
    "PH": {
        "code": "PH",
        "unicode": "U+1F1F5 U+1F1ED",
        "name": "Philippines",
        "emoji": "\ud83c\uddf5\ud83c\udded",
        "nativeName": "Pilipinas",
        "latlng": [13.0, 122.0]
    },
    "PK": {
        "code": "PK",
        "unicode": "U+1F1F5 U+1F1F0",
        "name": "Pakistan",
        "emoji": "\ud83c\uddf5\ud83c\uddf0",
        "nativeName": "Pakistan",
        "latlng": [30.0, 70.0]
    },
    "PL": {
        "code": "PL",
        "unicode": "U+1F1F5 U+1F1F1",
        "name": "Poland",
        "emoji": "\ud83c\uddf5\ud83c\uddf1",
        "nativeName": "Polska",
        "translations": { "DK": "Polen" },
        "latlng": [52.0, 20.0]
    },
    "PM": {
        "code": "PM",
        "unicode": "U+1F1F5 U+1F1F2",
        "name": "St. Pierre & Miquelon",
        "emoji": "\ud83c\uddf5\ud83c\uddf2",
        "nativeName": "Saint-Pierre-et-Miquelon",
        "latlng": [46.83333333, -56.33333333]
    },
    "PN": {
        "code": "PN",
        "unicode": "U+1F1F5 U+1F1F3",
        "name": "Pitcairn Islands",
        "emoji": "\ud83c\uddf5\ud83c\uddf3",
        "nativeName": "Pitcairn Islands",
        "latlng": [-25.06666666, -130.1]
    },
    "PR": {
        "code": "PR",
        "unicode": "U+1F1F5 U+1F1F7",
        "name": "Puerto Rico",
        "emoji": "\ud83c\uddf5\ud83c\uddf7",
        "nativeName": "Puerto Rico",
        "latlng": [18.25, -66.5]
    },
    "PS": {
        "code": "PS",
        "unicode": "U+1F1F5 U+1F1F8",
        "name": "Palestinian Territories",
        "emoji": "\ud83c\uddf5\ud83c\uddf8",
        "nativeName": "\u0641\u0644\u0633\u0637\u064a\u0646",
        "latlng": [31.9, 35.2]
    },
    "PT": {
        "code": "PT",
        "unicode": "U+1F1F5 U+1F1F9",
        "name": "Portugal",
        "emoji": "\ud83c\uddf5\ud83c\uddf9",
        "nativeName": "Portugal",
        "latlng": [39.5, -8.0]
    },
    "PW": {
        "code": "PW",
        "unicode": "U+1F1F5 U+1F1FC",
        "name": "Palau",
        "emoji": "\ud83c\uddf5\ud83c\uddfc",
        "nativeName": "Palau",
        "latlng": [7.5, 134.5]
    },
    "PY": {
        "code": "PY",
        "unicode": "U+1F1F5 U+1F1FE",
        "name": "Paraguay",
        "emoji": "\ud83c\uddf5\ud83c\uddfe",
        "nativeName": "Paraguay",
        "latlng": [-23.0, -58.0]
    },
    "QA": {
        "code": "QA",
        "unicode": "U+1F1F6 U+1F1E6",
        "name": "Qatar",
        "emoji": "\ud83c\uddf6\ud83c\udde6",
        "nativeName": "\u0642\u0637\u0631",
        "latlng": [25.5, 51.25]
    },
    "RE": {
        "code": "RE",
        "unicode": "U+1F1F7 U+1F1EA",
        "name": "R\u00e9union",
        "emoji": "\ud83c\uddf7\ud83c\uddea",
        "nativeName": "La R\u00e9union",
        "latlng": [-21.15, 55.5]
    },
    "RO": {
        "code": "RO",
        "unicode": "U+1F1F7 U+1F1F4",
        "name": "Romania",
        "emoji": "\ud83c\uddf7\ud83c\uddf4",
        "nativeName": "Rom\u00e2nia",
        "latlng": [46.0, 25.0]
    },
    "RS": {
        "code": "RS",
        "unicode": "U+1F1F7 U+1F1F8",
        "name": "Serbia",
        "emoji": "\ud83c\uddf7\ud83c\uddf8",
        "nativeName": "\u0421\u0440\u0431\u0438\u0458\u0430",
        "latlng": [44.0, 21.0]
    },
    "RU": {
        "code": "RU",
        "unicode": "U+1F1F7 U+1F1FA",
        "name": "Russia",
        "emoji": "\ud83c\uddf7\ud83c\uddfa",
        "nativeName": "\u0420\u043e\u0441\u0441\u0438\u044f",
        "translations": { "DK": "Rusland" },
        "latlng": [60.0, 100.0]
    },
    "RW": {
        "code": "RW",
        "unicode": "U+1F1F7 U+1F1FC",
        "name": "Rwanda",
        "emoji": "\ud83c\uddf7\ud83c\uddfc",
        "nativeName": "Rwanda",
        "latlng": [-2.0, 30.0]
    },
    "SA": {
        "code": "SA",
        "unicode": "U+1F1F8 U+1F1E6",
        "name": "Saudi Arabia",
        "emoji": "\ud83c\uddf8\ud83c\udde6",
        "nativeName": "\u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629",
        "latlng": [25.0, 45.0]
    },
    "SB": {
        "code": "SB",
        "unicode": "U+1F1F8 U+1F1E7",
        "name": "Solomon Islands",
        "emoji": "\ud83c\uddf8\ud83c\udde7",
        "nativeName": "Solomon Islands",
        "latlng": [-8.0, 159.0]
    },
    "SC": {
        "code": "SC",
        "unicode": "U+1F1F8 U+1F1E8",
        "name": "Seychelles",
        "emoji": "\ud83c\uddf8\ud83c\udde8",
        "nativeName": "Seychelles",
        "latlng": [-4.58333333, 55.66666666]
    },
    "SD": {
        "code": "SD",
        "unicode": "U+1F1F8 U+1F1E9",
        "name": "Sudan",
        "emoji": "\ud83c\uddf8\ud83c\udde9",
        "nativeName": "\u0627\u0644\u0633\u0648\u062f\u0627\u0646",
        "latlng": [15.0, 30.0]
    },
    "SE": {
        "code": "SE",
        "unicode": "U+1F1F8 U+1F1EA",
        "name": "Sweden",
        "emoji": "\ud83c\uddf8\ud83c\uddea",
        "nativeName": "Sverige",
        "latlng": [62.0, 15.0]
    },
    "SG": {
        "code": "SG",
        "unicode": "U+1F1F8 U+1F1EC",
        "name": "Singapore",
        "emoji": "\ud83c\uddf8\ud83c\uddec",
        "nativeName": "Singapore",
        "latlng": [1.36666666, 103.8]
    },
    "SH": {
        "code": "SH",
        "unicode": "U+1F1F8 U+1F1ED",
        "name": "St. Helena",
        "emoji": "\ud83c\uddf8\ud83c\udded",
        "nativeName": "Saint Helena",
        "latlng": [-15.95, -5.7]
    },
    "SI": {
        "code": "SI",
        "unicode": "U+1F1F8 U+1F1EE",
        "name": "Slovenia",
        "emoji": "\ud83c\uddf8\ud83c\uddee",
        "nativeName": "Slovenija",
        "latlng": [46.11666666, 14.81666666]
    },
    "SJ": {
        "code": "SJ",
        "unicode": "U+1F1F8 U+1F1EF",
        "name": "Svalbard & Jan Mayen",
        "emoji": "\ud83c\uddf8\ud83c\uddef",
        "nativeName": "Svalbard og Jan Mayen",
        "latlng": [78.0, 20.0]
    },
    "SK": {
        "code": "SK",
        "unicode": "U+1F1F8 U+1F1F0",
        "name": "Slovakia",
        "emoji": "\ud83c\uddf8\ud83c\uddf0",
        "nativeName": "Slovensko",
        "latlng": [48.66666666, 19.5]
    },
    "SL": {
        "code": "SL",
        "unicode": "U+1F1F8 U+1F1F1",
        "name": "Sierra Leone",
        "emoji": "\ud83c\uddf8\ud83c\uddf1",
        "nativeName": "Sierra Leone",
        "latlng": [8.5, -11.5]
    },
    "SM": {
        "code": "SM",
        "unicode": "U+1F1F8 U+1F1F2",
        "name": "San Marino",
        "emoji": "\ud83c\uddf8\ud83c\uddf2",
        "nativeName": "San Marino",
        "latlng": [43.76666666, 12.41666666]
    },
    "SN": {
        "code": "SN",
        "unicode": "U+1F1F8 U+1F1F3",
        "name": "Senegal",
        "emoji": "\ud83c\uddf8\ud83c\uddf3",
        "nativeName": "S\u00e9n\u00e9gal",
        "latlng": [14.0, -14.0]
    },
    "SO": {
        "code": "SO",
        "unicode": "U+1F1F8 U+1F1F4",
        "name": "Somalia",
        "emoji": "\ud83c\uddf8\ud83c\uddf4",
        "nativeName": "Soomaaliya",
        "latlng": [10.0, 49.0]
    },
    "SR": {
        "code": "SR",
        "unicode": "U+1F1F8 U+1F1F7",
        "name": "Suriname",
        "emoji": "\ud83c\uddf8\ud83c\uddf7",
        "nativeName": "Suriname",
        "latlng": [4.0, -56.0]
    },
    "SS": {
        "code": "SS",
        "unicode": "U+1F1F8 U+1F1F8",
        "name": "South Sudan",
        "emoji": "\ud83c\uddf8\ud83c\uddf8",
        "nativeName": "South Sudan",
        "latlng": [7.0, 30.0]
    },
    "ST": {
        "code": "ST",
        "unicode": "U+1F1F8 U+1F1F9",
        "name": "S\u00e3o Tom\u00e9 & Pr\u00edncipe",
        "emoji": "\ud83c\uddf8\ud83c\uddf9",
        "nativeName": "S\u00e3o Tom\u00e9 e Pr\u00edncipe",
        "latlng": [1.0, 7.0]
    },
    "SV": {
        "code": "SV",
        "unicode": "U+1F1F8 U+1F1FB",
        "name": "El Salvador",
        "emoji": "\ud83c\uddf8\ud83c\uddfb",
        "nativeName": "El Salvador",
        "latlng": [13.83333333, -88.91666666]
    },
    "SX": {
        "code": "SX",
        "unicode": "U+1F1F8 U+1F1FD",
        "name": "Sint Maarten",
        "emoji": "\ud83c\uddf8\ud83c\uddfd",
        "nativeName": "Sint Maarten",
        "latlng": [18.033333, -63.05]
    },
    "SY": {
        "code": "SY",
        "unicode": "U+1F1F8 U+1F1FE",
        "name": "Syria",
        "emoji": "\ud83c\uddf8\ud83c\uddfe",
        "nativeName": "\u0633\u0648\u0631\u064a\u0627",
        "latlng": [35.0, 38.0]
    },
    "SZ": {
        "code": "SZ",
        "unicode": "U+1F1F8 U+1F1FF",
        "name": "Swaziland",
        "emoji": "\ud83c\uddf8\ud83c\uddff",
        "nativeName": "Swaziland",
        "latlng": [-26.5, 31.5]
    },
    "TA": {
        "code": "TA",
        "unicode": "U+1F1F9 U+1F1E6",
        "name": "Tristan da Cunha",
        "emoji": "\ud83c\uddf9\ud83c\udde6"
    },
    "TC": {
        "code": "TC",
        "unicode": "U+1F1F9 U+1F1E8",
        "name": "Turks & Caicos Islands",
        "emoji": "\ud83c\uddf9\ud83c\udde8",
        "nativeName": "Turks and Caicos Islands",
        "latlng": [21.75, -71.58333333]
    },
    "TD": {
        "code": "TD",
        "unicode": "U+1F1F9 U+1F1E9",
        "name": "Chad",
        "emoji": "\ud83c\uddf9\ud83c\udde9",
        "nativeName": "Tchad",
        "latlng": [15.0, 19.0]
    },
    "TF": {
        "code": "TF",
        "unicode": "U+1F1F9 U+1F1EB",
        "name": "French Southern Territories",
        "emoji": "\ud83c\uddf9\ud83c\uddeb",
        "nativeName": "Territoire des Terres australes et antarctiques fran\u00e7aises",
        "latlng": [-49.25, 69.167]
    },
    "TG": {
        "code": "TG",
        "unicode": "U+1F1F9 U+1F1EC",
        "name": "Togo",
        "emoji": "\ud83c\uddf9\ud83c\uddec",
        "nativeName": "Togo",
        "latlng": [8.0, 1.16666666]
    },
    "TH": {
        "code": "TH",
        "unicode": "U+1F1F9 U+1F1ED",
        "name": "Thailand",
        "emoji": "\ud83c\uddf9\ud83c\udded",
        "nativeName": "\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28\u0e44\u0e17\u0e22",
        "latlng": [15.0, 100.0]
    },
    "TJ": {
        "code": "TJ",
        "unicode": "U+1F1F9 U+1F1EF",
        "name": "Tajikistan",
        "emoji": "\ud83c\uddf9\ud83c\uddef",
        "nativeName": "\u0422\u043e\u04b7\u0438\u043a\u0438\u0441\u0442\u043e\u043d",
        "latlng": [39.0, 71.0]
    },
    "TK": {
        "code": "TK",
        "unicode": "U+1F1F9 U+1F1F0",
        "name": "Tokelau",
        "emoji": "\ud83c\uddf9\ud83c\uddf0",
        "nativeName": "Tokelau",
        "latlng": [-9.0, -172.0]
    },
    "TL": {
        "code": "TL",
        "unicode": "U+1F1F9 U+1F1F1",
        "name": "Timor-Leste",
        "emoji": "\ud83c\uddf9\ud83c\uddf1",
        "nativeName": "Timor-Leste",
        "latlng": [-8.83333333, 125.91666666]
    },
    "TM": {
        "code": "TM",
        "unicode": "U+1F1F9 U+1F1F2",
        "name": "Turkmenistan",
        "emoji": "\ud83c\uddf9\ud83c\uddf2",
        "nativeName": "T\u00fcrkmenistan",
        "latlng": [40.0, 60.0]
    },
    "TN": {
        "code": "TN",
        "unicode": "U+1F1F9 U+1F1F3",
        "name": "Tunisia",
        "emoji": "\ud83c\uddf9\ud83c\uddf3",
        "nativeName": "\u062a\u0648\u0646\u0633",
        "latlng": [34.0, 9.0]
    },
    "TO": {
        "code": "TO",
        "unicode": "U+1F1F9 U+1F1F4",
        "name": "Tonga",
        "emoji": "\ud83c\uddf9\ud83c\uddf4",
        "nativeName": "Tonga",
        "latlng": [-20.0, -175.0]
    },
    "TR": {
        "code": "TR",
        "unicode": "U+1F1F9 U+1F1F7",
        "name": "Turkey",
        "emoji": "\ud83c\uddf9\ud83c\uddf7",
        "nativeName": "T\u00fcrkiye",
        "latlng": [39.0, 35.0]
    },
    "TT": {
        "code": "TT",
        "unicode": "U+1F1F9 U+1F1F9",
        "name": "Trinidad & Tobago",
        "emoji": "\ud83c\uddf9\ud83c\uddf9",
        "nativeName": "Trinidad and Tobago",
        "latlng": [11.0, -61.0]
    },
    "TV": {
        "code": "TV",
        "unicode": "U+1F1F9 U+1F1FB",
        "name": "Tuvalu",
        "emoji": "\ud83c\uddf9\ud83c\uddfb",
        "nativeName": "Tuvalu",
        "latlng": [-8.0, 178.0]
    },
    "TW": {
        "code": "TW",
        "unicode": "U+1F1F9 U+1F1FC",
        "name": "Taiwan",
        "emoji": "\ud83c\uddf9\ud83c\uddfc",
        "nativeName": "\u81fa\u7063",
        "latlng": [23.5, 121.0]
    },
    "TZ": {
        "code": "TZ",
        "unicode": "U+1F1F9 U+1F1FF",
        "name": "Tanzania",
        "emoji": "\ud83c\uddf9\ud83c\uddff",
        "nativeName": "Tanzania",
        "latlng": [-6.0, 35.0]
    },
    "UA": {
        "code": "UA",
        "unicode": "U+1F1FA U+1F1E6",
        "name": "Ukraine",
        "emoji": "\ud83c\uddfa\ud83c\udde6",
        "nativeName": "\u0423\u043a\u0440\u0430\u0457\u043d\u0430",
        "latlng": [49.0, 32.0]
    },
    "UG": {
        "code": "UG",
        "unicode": "U+1F1FA U+1F1EC",
        "name": "Uganda",
        "emoji": "\ud83c\uddfa\ud83c\uddec",
        "nativeName": "Uganda",
        "latlng": [1.0, 32.0]
    },
    "UM": {
        "code": "UM",
        "unicode": "U+1F1FA U+1F1F2",
        "name": "U.S. Outlying Islands",
        "emoji": "\ud83c\uddfa\ud83c\uddf2",
        "nativeName": "United States Minor Outlying Islands",
        "latlng": []
    },
    "UN": {
        "code": "UN",
        "unicode": "U+1F1FA U+1F1F3",
        "name": "United Nations",
        "emoji": "\ud83c\uddfa\ud83c\uddf3"
    },
    "US": {
        "code": "US",
        "unicode": "U+1F1FA U+1F1F8",
        "name": "United States",
        "emoji": "\ud83c\uddfa\ud83c\uddf8",
        "nativeName": "United States",
        "latlng": [38.0, -97.0]
    },
    "UY": {
        "code": "UY",
        "unicode": "U+1F1FA U+1F1FE",
        "name": "Uruguay",
        "emoji": "\ud83c\uddfa\ud83c\uddfe",
        "nativeName": "Uruguay",
        "latlng": [-33.0, -56.0]
    },
    "UZ": {
        "code": "UZ",
        "unicode": "U+1F1FA U+1F1FF",
        "name": "Uzbekistan",
        "emoji": "\ud83c\uddfa\ud83c\uddff",
        "nativeName": "O\u2018zbekiston",
        "latlng": [41.0, 64.0]
    },
    "VA": {
        "code": "VA",
        "unicode": "U+1F1FB U+1F1E6",
        "name": "Vatican City",
        "emoji": "\ud83c\uddfb\ud83c\udde6",
        "nativeName": "Sancta Sedes",
        "latlng": [41.9, 12.45]
    },
    "VC": {
        "code": "VC",
        "unicode": "U+1F1FB U+1F1E8",
        "name": "St. Vincent & Grenadines",
        "emoji": "\ud83c\uddfb\ud83c\udde8",
        "nativeName": "Saint Vincent and the Grenadines",
        "latlng": [13.25, -61.2]
    },
    "VE": {
        "code": "VE",
        "unicode": "U+1F1FB U+1F1EA",
        "name": "Venezuela",
        "emoji": "\ud83c\uddfb\ud83c\uddea",
        "nativeName": "Venezuela",
        "latlng": [8.0, -66.0]
    },
    "VG": {
        "code": "VG",
        "unicode": "U+1F1FB U+1F1EC",
        "name": "British Virgin Islands",
        "emoji": "\ud83c\uddfb\ud83c\uddec",
        "nativeName": "British Virgin Islands",
        "latlng": [18.431383, -64.62305]
    },
    "VI": {
        "code": "VI",
        "unicode": "U+1F1FB U+1F1EE",
        "name": "U.S. Virgin Islands",
        "emoji": "\ud83c\uddfb\ud83c\uddee",
        "nativeName": "Virgin Islands of the United States",
        "latlng": [18.34, -64.93]
    },
    "VN": {
        "code": "VN",
        "unicode": "U+1F1FB U+1F1F3",
        "name": "Vietnam",
        "emoji": "\ud83c\uddfb\ud83c\uddf3",
        "nativeName": "Vi\u1ec7t Nam",
        "latlng": [16.16666666, 107.83333333]
    },
    "VU": {
        "code": "VU",
        "unicode": "U+1F1FB U+1F1FA",
        "name": "Vanuatu",
        "emoji": "\ud83c\uddfb\ud83c\uddfa",
        "nativeName": "Vanuatu",
        "latlng": [-16.0, 167.0]
    },
    "WF": {
        "code": "WF",
        "unicode": "U+1F1FC U+1F1EB",
        "name": "Wallis & Futuna",
        "emoji": "\ud83c\uddfc\ud83c\uddeb",
        "nativeName": "Wallis et Futuna",
        "latlng": [-13.3, -176.2]
    },
    "WS": {
        "code": "WS",
        "unicode": "U+1F1FC U+1F1F8",
        "name": "Samoa",
        "emoji": "\ud83c\uddfc\ud83c\uddf8",
        "nativeName": "Samoa",
        "latlng": [-13.58333333, -172.33333333]
    },
    "XK": {
        "code": "XK",
        "unicode": "U+1F1FD U+1F1F0",
        "name": "Kosovo",
        "emoji": "\ud83c\uddfd\ud83c\uddf0",
        "nativeName": "Republika e Kosov\u00ebs",
        "latlng": [42.666667, 21.166667]
    },
    "YE": {
        "code": "YE",
        "unicode": "U+1F1FE U+1F1EA",
        "name": "Yemen",
        "emoji": "\ud83c\uddfe\ud83c\uddea",
        "nativeName": "\u0627\u0644\u064a\u064e\u0645\u064e\u0646",
        "latlng": [15.0, 48.0]
    },
    "YT": {
        "code": "YT",
        "unicode": "U+1F1FE U+1F1F9",
        "name": "Mayotte",
        "emoji": "\ud83c\uddfe\ud83c\uddf9",
        "nativeName": "Mayotte",
        "latlng": [-12.83333333, 45.16666666]
    },
    "ZA": {
        "code": "ZA",
        "unicode": "U+1F1FF U+1F1E6",
        "name": "South Africa",
        "emoji": "\ud83c\uddff\ud83c\udde6",
        "nativeName": "South Africa",
        "latlng": [-29.0, 24.0]
    },
    "ZM": {
        "code": "ZM",
        "unicode": "U+1F1FF U+1F1F2",
        "name": "Zambia",
        "emoji": "\ud83c\uddff\ud83c\uddf2",
        "nativeName": "Zambia",
        "latlng": [-15.0, 30.0]
    },
    "ZW": {
        "code": "ZW",
        "unicode": "U+1F1FF U+1F1FC",
        "name": "Zimbabwe",
        "emoji": "\ud83c\uddff\ud83c\uddfc",
        "nativeName": "Zimbabwe",
        "latlng": [-20.0, 30.0]
    }
}`)
