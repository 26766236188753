import { Typography } from '@mui/material'
import * as React from 'react'
import PickBank from '../../../../components/Bank/PickBankCountry'
import AppPageWraper from '../../../../components/PageWrappers/AppPageWraper'

function PickbankCountry() {
    return (
        <AppPageWraper>
            <Typography variant="h6">Vælg det land din bank tilhører</Typography>
            <PickBank />
        </AppPageWraper>
    )
}

export default PickbankCountry
